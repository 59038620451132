import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom" // Import useNavigate from React Router
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import NotificationModal from "../../common/NotificationModal"
import {
  onUserSignInRequest,
  onUserSignInClick,
  onUserSignOut,
  closeMessage,
  clearStationState,
  clearUserState,
  resetAllReducers,
} from "../../../actions"

import "./style.scss"

const LoginScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authenticated, userData, isLoading, showMessage } = useSelector(
    (state) => state.users
  )

  const INIT_STATE = {
    mobile: "",
    passw: "",
    validField: {
      mobile: {
        type: "mobile",
        isRequired: true,
        isChecked: false,
        isValid: false,
      },
      passw: {
        type: "text",
        isRequired: true,
        isChecked: false,
        isValid: false,
      },
    },
  }

  const [state, setState] = useState(INIT_STATE)

  useEffect(() => {
    dispatch(resetAllReducers())
  }, [])

  useEffect(() => {
    if (!authenticated) {
      dispatch(onUserSignOut())
    }
    setState(INIT_STATE)
    dispatch(clearStationState())
  }, [authenticated, dispatch])

  useEffect(() => {
    if (userData) {
      const isNeedChangePassword =
        userData.isPasswordTemporary || !userData.isStrongPassword

      if (isNeedChangePassword) {
        navigate("ChangePasswordScreen")
        return
      }

      switch (userData.orderTypeGroup) {
        case "5":
        case "6":
          navigate("StationSelectScreen")
          break
        case "3":
        case "4":
          navigate("OrdersSearchScreen")
          break
        case "2":
          navigate("StationsScreen")
          break
        case "1":
          if (userData.role === "Exelot") {
            navigate("StationsScreen")
          } else {
            navigate("OrdersSearchScreen")
          }
          break
        default:
          dispatch(clearUserState())
          navigate("/")
          alert(`תפקיד זה לא מוגדר במערכת`)
      }
    }
  }, [userData, dispatch, navigate])

  const setValue = (inputName, e) => {
    setState((prevState) => ({
      ...prevState,
      [inputName]: e.target.value,
    }))
  }

  const onInputFocus = (inputName) => {
    let { validField } = state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    setState({
      ...state,
      validField,
    })
  }

  const validateInputValue = (inputName, value) => {
    let { validField } = state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(value, field.type, field.isRequired)
    validField[inputName] = field

    setState({
      ...state,
      validField,
    })
  }

  const onClickLoginButton = () => {
    const { validField, mobile, passw } = state
    validateInputValue("mobile", mobile)
    validateInputValue("passw", passw)
    const isValid =
      validField.passw.isChecked &&
      validField.passw.isValid &&
      validField.mobile.isChecked &&
      validField.mobile.isValid
    if (!isValid) {
      return
    }
    dispatch(onUserSignInRequest())
    dispatch(onUserSignInClick(mobile, passw))
  }

  const onMessageClickOk = () => {
    dispatch(closeMessage())
  }

  if (isLoading) {
    return (
      <div>
        <Title text="כניסה למערכת" />
        <Loader show={isLoading} />
      </div>
    )
  }

  return (
    <div className="login">
      <Title text="כניסה למערכת" />
      <form>
        <InputField
          isFocused={true}
          show={true}
          value={state.mobile}
          placeholder="מספר טלפון"
          mandatory={true}
          showValidation={state.validField.mobile.isChecked}
          showValidationOk={state.validField.mobile.isValid}
          showValidationError={!state.validField.mobile.isValid}
          validationErrorMessage="מספר טלפון לא תקין"
          onFocus={() => onInputFocus("mobile")}
          onBlur={(e) => validateInputValue("mobile", e.target.value)}
          onChange={(e) => setValue("mobile", e)}
          maxLength={10}
        />
        <InputField
          show={true}
          value={state.passw}
          iconStyle=""
          placeholder="סיסמא"
          mandatory={true}
          cc={state.validField.passw.isChecked}
          showValidationOk={state.validField.passw.isValid}
          showValidationError={!state.validField.passw.isValid}
          validationErrorMessage="סיסמא לא תקינה"
          onFocus={() => onInputFocus("passw")}
          onBlur={(e) => validateInputValue("passw", e.target.value)}
          onChange={(e) => setValue("passw", e)}
          type="password"
          maxLength={20}
        />
        <div className="buttons-wrapper">
          <Button onClick={onClickLoginButton}>כניסה</Button>
        </div>
        <NotificationModal
          show={showMessage}
          text={
            <div className="login-error">
              <div className="error-title">שגיאת התחברות</div>
              <div className="error-text">אנא בדוק את הפרטים ונסה שוב</div>
            </div>
          }
          onOkClick={onMessageClickOk}
        />
      </form>
    </div>
  )
}

export default LoginScreen
