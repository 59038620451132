import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { Container } from "reactstrap"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import { ButtonRectangle } from "../../../common/ButtonRectangle"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  createColumnHelper,
} from "@tanstack/react-table"
import "./style.scss"

const columnHelper = createColumnHelper()

const UsersList = (props) => {
  const dispatch = useDispatch()
  const {
    onClickDelete,
    onClickResetPass,
    onClickResetBOPass,
    onClickSetAsManager,
    onClickRemoveFromManagerList,
    refreshTable,
    orderTypeGroup,
    usersListArr,
  } = props

  const formateUser = (user) => {
    const { firstName, lastName, mobilePhone, id, isBOUser, boId } = user
    return {
      id: id,
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      isBOUser: isBOUser === true ? "מנהל" : "נהג",
      boId: boId,
    }
  }

  const formattedList = useMemo(() => {
    return (usersListArr || []).map((order) => formateUser(order))
  }, [usersListArr])

  const data = useMemo(
    () => (usersListArr ? formattedList : []),
    [usersListArr]
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor("isBOUser", {
        header: "מנהל / נהג",
        filterFn: "equals",
        cell: ({ row }) => {
          const { isBOUser } = row.original
          return (
            <i
              className={
                isBOUser === "מנהל"
                  ? "fa fa-briefcase blue"
                  : orderTypeGroup === "6"
                  ? "fa fa-user green"
                  : "fa fa-truck orange"
              }
              aria-hidden="true"
            ></i>
          )
        },
        filter: SelectColumnFilter,
      }),
      columnHelper.accessor("firstName", {
        id: "firstName",
        header: () => "שם פרטי",
      }),
      columnHelper.accessor("lastName", {
        id: "lastName",
        header: () => "שם משפחה",
      }),
      columnHelper.accessor("mobilePhone", {
        id: "mobilePhone",
        header: () => "מספר טלפון",
        cell: (info) => info.getValue() || "N/A",
      }),
      columnHelper.display({
        id: "pass",
        cell: ({ row }) => (
          <ButtonRectangle
            extraClass="btn-reset-pass"
            onClick={() => onClickResetPass(row.original.id)}
          >
            איפוס סיסמת בלדר
          </ButtonRectangle>
        ),
      }),
      columnHelper.display({
        id: "setBOUser",
        cell: ({ row }) => (
          <ButtonRectangle
            extraClass="btn-reset-pass"
            onClick={() =>
              row.original.isBOUser === "מנהל"
                ? onClickRemoveFromManagerList(row.original.id)
                : onClickSetAsManager(row.original.id)
            }
          >
            {row.original.isBOUser === "מנהל" ? "הסר ממנהלים" : "להפוך למנהל"}
          </ButtonRectangle>
        ),
      }),
      columnHelper.display({
        id: "resetBOUserPassword",
        cell: ({ row }) =>
          row.original.isBOUser === "מנהל" && (
            <ButtonRectangle
              extraClass="btn-reset-pass"
              onClick={() => onClickResetBOPass(row.original.boId)}
            >
              {"איפוס סיסמת מנהל"}
            </ButtonRectangle>
          ),
      }),
      columnHelper.display({
        id: "key",
        cell: ({ row }) => (
          <ButtonRectangle
            extraClass="btn-delete"
            onClick={() => onClickDelete(row.original.id)}
          >
            מחיקת בלדר
          </ButtonRectangle>
        ),
      }),
    ],
    [
      orderTypeGroup,
      onClickDelete,
      onClickResetPass,
      onClickSetAsManager,
      onClickRemoveFromManagerList,
      onClickResetBOPass,
    ]
  )

  return (
    <div className="user-list-wrapper">
      <Container className="user-list">
        <TableContainer
          columns={columns}
          data={data}
          refreshTable={refreshTable}
          showTablePagination={true}
        />
      </Container>
    </div>
  )
}

export default UsersList
