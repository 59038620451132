import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Title from "../../common/Title"
import Loader from "../../common/Loader"

import {
  onStartLoading,
  onFetchExistOrders,
  setSelectedOrder,
  fetchOrderStatuses,
  clearStationState,
  onGetStations,
} from "../../../actions"

import "./style.scss"
import OrdersList from "./OrdersList"

const OrdersListScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Select state from Redux store
  const { authenticated, isLoading, orderList, userData, ordersStatusesArr } =
    useSelector((state) => ({
      authenticated: state.users.authenticated,
      isLoading: state.orders.isLoading,
      orderList: state.orders.orderList,
      userData: state.users.userData,
      ordersStatusesArr: state.orderStatuses.ordersStatusesArr,
    }))

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }

    dispatch(onStartLoading())
    dispatch(fetchOrderStatuses())
    dispatch(onFetchExistOrders())
    dispatch(onGetStations())
  }, [authenticated, navigate, dispatch])

  const onClickSetOrderToUpdate = (orderNumber) => {
    const selectedOrder = orderList.find((o) => o.orderNumber === orderNumber)
    dispatch(setSelectedOrder(selectedOrder))
    navigate("/UpdateOrderScreen")
  }

  const onRelocateClick = (orderNumber) => {
    const selectedOrder = orderList.find((o) => o.orderNumber === orderNumber)
    dispatch(setSelectedOrder(selectedOrder))
    navigate("/RelocateOrderScreen")
  }

  const refreshTable = () => {
    dispatch(onFetchExistOrders())
  }

  if (isLoading) {
    return (
      <div>
        <Title text="רשימת הזמנות" />
        <Loader show={isLoading} />
      </div>
    )
  }

  return (
    <div>
      <Title text="רשימת הזמנות" />
      {ordersStatusesArr && ordersStatusesArr.length > 0 ? (
        <OrdersList
          onClick={onClickSetOrderToUpdate}
          onRelocateClick={onRelocateClick}
          refreshTable={refreshTable}
        />
      ) : (
        "Loading..."
      )}
    </div>
  )
}

export default OrdersListScreen
