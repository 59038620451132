import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import { isValidField } from "../../../utils/FieldsValidation"
import Message from "../../common/Message"
import Title from "../../common/Title"
import {
  updateUserPassword,
  clearUserState,
  clearRequestData,
} from "../../../actions"
import eyeIcon from "../../../assets/images/eye.svg"
import "./style.scss"

const ChangePasswordScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    authenticated,
    userData,
    createUpdateRequestError,
    createUpdateRequsetSuccess,
  } = useSelector((state) => state.users)

  const INIT_STATE = {
    newPassword: "",
    confirmPassword: "",
    isMatchError: false,
    isShowPassword: false,
    isShowConfirmPassword: false,
    validField: {
      newPassword: { isRequired: true, isChecked: false, isValid: false },
      confirmPassword: { isRequired: true, isChecked: false, isValid: false },
    },
  }

  const [state, setState] = useState(INIT_STATE)

  useEffect(() => {
    if (!authenticated) navigate("/")
  }, [authenticated, navigate])

  const validateInputValue = (inputName, value, equalToValue) => {
    let updatedValidField = { ...state.validField }
    let field = updatedValidField[inputName]
    if (!field) return

    field.isChecked = true
    if (inputName === "confirmPassword") {
      field.compareMinValue = equalToValue
    }

    field.isValid = isValidField(
      value,
      inputName === "confirmPassword" ? "passwordConfirm" : "password",
      field.isRequired,
      field.compareMinValue
    )
    updatedValidField[inputName] = field

    setState((prev) => ({ ...prev, validField: updatedValidField }))
  }

  const handlePasswordChange = () => {
    validateInputValue("newPassword", state.newPassword)
    validateInputValue(
      "confirmPassword",
      state.confirmPassword,
      state.newPassword
    )

    const isValid =
      state.validField.newPassword.isChecked &&
      state.validField.newPassword.isValid &&
      state.validField.confirmPassword.isChecked &&
      state.validField.confirmPassword.isValid

    if (state.newPassword !== state.confirmPassword) {
      setState((prev) => ({ ...prev, isMatchError: true }))
      return
    }

    if (!isValid) return

    dispatch(
      updateUserPassword({ password: state.newPassword, id: userData.userId })
    )
  }

  return (
    <div className="changePassword">
      <Title text="שינוי סיסמה" />
      <div className="input-wrapper">
        <img
          className="eye-icon"
          src={eyeIcon}
          alt="show password icon"
          onClick={() =>
            setState((prev) => ({
              ...prev,
              isShowPassword: !prev.isShowPassword,
            }))
          }
        />
        <InputField
          show={true}
          value={state.newPassword}
          placeholder="סיסמא חדשה"
          mandatory
          showValidation={state.validField.newPassword.isChecked}
          showValidationOk={state.validField.newPassword.isValid}
          showValidationError={!state.validField.newPassword.isValid}
          validationErrorMessage="מינימום 8 תווים..."
          onChange={(e) => {
            validateInputValue("newPassword", e.target.value)
            validateInputValue(
              "confirmPassword",
              state.confirmPassword,
              e.target.value
            )
            setState((prev) => ({ ...prev, newPassword: e.target.value }))
          }}
          type={state.isShowPassword ? "text" : "password"}
          maxLength={20}
        />
      </div>
      <div className="input-wrapper">
        <img
          className="eye-icon"
          src={eyeIcon}
          alt="show confirm password icon"
          onClick={() =>
            setState((prev) => ({
              ...prev,
              isShowConfirmPassword: !prev.isShowConfirmPassword,
            }))
          }
        />
        <InputField
          show={true}
          value={state.confirmPassword}
          placeholder="אימות סיסמא"
          mandatory
          showValidation={state.validField.confirmPassword.isChecked}
          showValidationOk={state.validField.confirmPassword.isValid}
          showValidationError={!state.validField.confirmPassword.isValid}
          validationErrorMessage="לא תואם לסיסמא"
          onChange={(e) => {
            validateInputValue(
              "confirmPassword",
              e.target.value,
              state.newPassword
            )
            setState((prev) => ({ ...prev, confirmPassword: e.target.value }))
          }}
          type={state.isShowConfirmPassword ? "text" : "password"}
          maxLength={20}
        />
      </div>
      <div className="buttons-wrapper">
        <Button onClick={handlePasswordChange}>שינוי סיסמה</Button>
      </div>
      <Message
        show={state.isMatchError}
        isError
        successText=""
        errorText="סיסמאות לא תואמות"
        onClick={() => setState((prev) => ({ ...prev, isMatchError: false }))}
      />
      <Message
        show={createUpdateRequsetSuccess}
        isError={false}
        successText="סיסמתך שונתה בהצלחה"
        errorText=""
        onClick={() => {
          dispatch(clearUserState())
          navigate("/")
        }}
      />
      <Message
        show={createUpdateRequestError}
        isError
        successText=""
        errorText="שגיאה, אנא נסה שנית או פנה לשירות לקוחות"
        onClick={() => dispatch(clearRequestData())}
      />
    </div>
  )
}

export default ChangePasswordScreen
