import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { H1 } from "../../common/H1"
import { LabeledSelect } from "../../common/LabeledSelect"
import { Button } from "../../common/Button"
import { downloadPackageDebitReport } from "../../../actions"
import "./style.scss"

const reportHeader = 'דו"ח חיובי חבילות'
const monthLabel = "בחר חודש"
const yearLabel = "בחר שנה"
const orderTypeLabel = "בחר סוג הזמנה"

const startYear = 2021

const setYearList = () => {
  let years = []
  let now = new Date()
  now = now.getFullYear()
  for (var i = startYear; i <= now; i++) {
    years[i] = i
  }
  return years
}

const setMonthList = () => {
  let monthNames = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ]
  let month = []
  for (var i = 0; i < monthNames.length; i++) {
    month[i + 1] = monthNames[i]
  }
  return month
}

const setOrderTypeList = () => {
  let orderType = []
  orderType[13] = "חבילות DHL"
  orderType[14] = "חבילות BAR"
  orderType[15] = "חבילות UPS"
  orderType[6] = "החזרות DHL"
  orderType[17] = "חבילות יד מרדכי"
  orderType[18] = "חבילות גפן מדיקל"
  orderType[7] = "אקסלוט החזרות"
  return orderType
}

const initMonth = () => {
  let now = new Date()
  let month = now.getMonth()
  if (month === 0) {
    month = 12
  }
  return month
}

const initYear = () => {
  let now = new Date()
  let year = now.getFullYear()
  if (now.getMonth() === 0) {
    year--
  }
  return year
}

const initOrderType = () => {
  return 13 // DHL
}

const PackageDebitReport = () => {
  const [selectedMonth, setSelectedMonth] = useState(initMonth())
  const [selectedYear, setSelectedYear] = useState(initYear())
  const [selectedOrderType, setSelectedOrderType] = useState(initOrderType())

  const [monthOptions] = useState(setMonthList())
  const [yearOptions] = useState(setYearList())
  const [orderTypeOptions] = useState(setOrderTypeList())

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authenticated } = useSelector((state) => state.users)

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
    }
  }, [authenticated, navigate])

  const onDataChange = (property, value) => {
    if (property === "selectedMonth") setSelectedMonth(value)
    if (property === "selectedYear") setSelectedYear(value)
    if (property === "selectedOrderType") setSelectedOrderType(value)
  }

  const onDownloadClick = (event) => {
    event.preventDefault()
    dispatch(
      downloadPackageDebitReport(selectedMonth, selectedYear, selectedOrderType)
    )
  }

  return (
    <div className="packageDebitReport">
      <H1 className="reports-section-header" title={reportHeader} />
      <form onSubmit={onDownloadClick}>
        <div className="form-group">
          <div className="date-wrapper">
            <LabeledSelect
              extraClass={"label-box"}
              labelText={monthLabel}
              id="month"
              options={monthOptions}
              value={selectedMonth}
              emptyOption={false}
              onChange={(e) => onDataChange("selectedMonth", e.target.value)}
            />
            <LabeledSelect
              extraClass={"label-box"}
              labelText={yearLabel}
              id="year"
              options={yearOptions}
              value={selectedYear}
              emptyOption={false}
              onChange={(e) => onDataChange("selectedYear", e.target.value)}
            />
          </div>
        </div>
        <div className="btn-download-wrapper">
          <Button type="submit">הורד</Button>
        </div>
      </form>
    </div>
  )
}

export default PackageDebitReport
