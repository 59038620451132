import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
import {
  fetchAllUsers,
  setSelectedOrder,
  deleteUser,
  clearRequestData,
  resetPassword,
  resetBOPassword,
  addBOUser,
  RemoveBOUser,
} from "../../../actions"
import "./style.scss"
import UsersList from "./UsersList"
import YesNoModal from "../../common/YesNoModal"
import NotificationModal from "../../common/NotificationModal"
import { useNavigate } from "react-router-dom"

const UsersListScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    authenticated,
    isLoading,
    userDeletedSuccess,
    userDeleteFail,
    isPasswordResetSucces,
    isPasswordResetFail,
    isNeedReloadUsers,
    usersListArr,
  } = useSelector((state) => state.users || {})

  const { role, accountType, accountTypeId, orderTypeGroup } = useSelector(
    (state) => state.users.userData || {}
  )

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [showResetBOPasswordModal, setShowResetBOPasswordModal] =
    useState(false)
  const [showAddBOUserModal, setShowAddBOUserModal] = useState(false)
  const [showRemoveBOUserModal, setShowRemoveBOUserModal] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(null)
  const [userIdToResetPassowrd, setUserIdToResetPassowrd] = useState(null)
  const [boUserIdToResetPassowrd, setBoUserIdToResetPassowrd] = useState(null)
  const [userIdToBOUser, setUserIdToBOUser] = useState(null)

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }
    dispatch(fetchAllUsers())
  }, [])

  useEffect(() => {
    if (userDeletedSuccess || isNeedReloadUsers) {
      dispatch(clearRequestData())
      dispatch(fetchAllUsers())
    }
  }, [userDeletedSuccess, isNeedReloadUsers, dispatch])

  const onDeleteUserClick = (userId) => {
    setShowDeleteUserModal(true)
    setUserIdToDelete(userId)
  }
  const onResetPasswordClick = (userId) => {
    setShowResetPasswordModal(true)
    setUserIdToResetPassowrd(userId)
  }
  const onResetBOPasswordClick = (boUserId) => {
    setShowResetBOPasswordModal(true)
    setBoUserIdToResetPassowrd(boUserId)
  }
  const onClickSetAsManager = (userId) => {
    setShowAddBOUserModal(true)
    setUserIdToBOUser(userId)
  }
  const onClickRemoveFromManagerList = (userId) => {
    setShowRemoveBOUserModal(true)
    setUserIdToBOUser(userId)
  }

  const onNewUserClick = () => navigate("/NewUser")

  const confirmDeleteUser = () => {
    dispatch(deleteUser(userIdToDelete))
    setShowDeleteUserModal(false)
  }

  const confirmResetPassword = () => {
    dispatch(resetPassword(userIdToResetPassowrd))
    setShowResetPasswordModal(false)
  }

  const confirmResetBOPassword = () => {
    dispatch(resetBOPassword(boUserIdToResetPassowrd))
    setShowResetBOPasswordModal(false)
  }

  const confirmAddBOUser = () => {
    dispatch(addBOUser(userIdToBOUser, role, accountTypeId))
    setShowAddBOUserModal(false)
  }

  const confirmRemoveBOUser = () => {
    dispatch(RemoveBOUser(userIdToBOUser, role, accountTypeId))
    setShowRemoveBOUserModal(false)
  }

  const refreshTable = () => dispatch(fetchAllUsers())

  if (isLoading) {
    return (
      <div>
        <Title text="רשימת משתמשים" />
        <Loader show={isLoading} />
      </div>
    )
  }

  return (
    <div className="user-list">
      <Title text="רשימת משתמשים" />
      <div className="btn-new-user" onClick={onNewUserClick}>
        יצירת משתמש חדש
      </div>
      {usersListArr.length > 0 && (
        <UsersList
          onClickDelete={onDeleteUserClick}
          onClickResetPass={onResetPasswordClick}
          onClickResetBOPass={onResetBOPasswordClick}
          onClickSetAsManager={onClickSetAsManager}
          onClickRemoveFromManagerList={onClickRemoveFromManagerList}
          refreshTable={refreshTable}
          usersListArr={usersListArr}
          orderTypeGroup={orderTypeGroup}
        />
      )}

      <YesNoModal
        show={showDeleteUserModal}
        title={"ניהול משתמשים"}
        text={"האם אתה בטוח שברצונך למחוק משתמש זה?"}
        onYesClick={confirmDeleteUser}
        onNoClick={() => setShowDeleteUserModal(false)}
      />
      <NotificationModal
        show={userDeleteFail}
        title={"ניהול משתמשים"}
        text={"כישלון במחיקת משתמש"}
        onOkClick={() => dispatch(clearRequestData())}
      />
      <NotificationModal
        show={isPasswordResetSucces}
        title={"ניהול משתמשים"}
        text={"איפוס סיסמה עבר בהצלחה, סיסמה חדשה נשלחה בסמס."}
        onOkClick={() => dispatch(clearRequestData())}
      />
      <NotificationModal
        show={isPasswordResetFail}
        title={"ניהול משתמשים"}
        text={"איפוס סיסמה נכשל"}
        onOkClick={() => dispatch(clearRequestData())}
      />
      <YesNoModal
        show={showResetPasswordModal}
        title={"ניהול משתמשים"}
        text={"האם אתה בטוח שברצונך לאפס סיסמת בלדר למשתמש הזה?"}
        onYesClick={confirmResetPassword}
        onNoClick={() => setShowResetPasswordModal(false)}
      />
      <YesNoModal
        show={showResetBOPasswordModal}
        title={"ניהול משתמשים"}
        text={"?האם אתה בטוח שברצונך לאפס סיסמת מנהל למשתמש הזה"}
        onYesClick={confirmResetBOPassword}
        onNoClick={() => setShowResetBOPasswordModal(false)}
      />
      <YesNoModal
        show={showAddBOUserModal}
        title={"ניהול משתמשים"}
        text={"?האם אתה בטוח שברצונך להוסיף את המשתמש לרשימת מנהלים"}
        onYesClick={confirmAddBOUser}
        onNoClick={() => setShowAddBOUserModal(false)}
      />
      <YesNoModal
        show={showRemoveBOUserModal}
        title={"ניהול משתמשים"}
        text={"?האם אתה בטוח שברצונך להסיר את המשתמש מרשימת מנהלים"}
        onYesClick={confirmRemoveBOUser}
        onNoClick={() => setShowRemoveBOUserModal(false)}
      />
    </div>
  )
}

export default UsersListScreen
