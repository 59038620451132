import React, { useEffect } from "react"
import { ButtonRectangle } from "../../common/ButtonRectangle"
import OrderHistoryList from "../OrderHistoryModal/OrderHistotyList"
import "./style.scss"

const OrderHistoryModal = ({
  isShow,
  order,
  setViewHistory,
  getFormatedDate,
  statuses,
}) => {
  if (!isShow) {
    return null
  }

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && setViewHistory(false)}
      role="dialog"
      aria-modal="true"
    >
      <div className="modal-container" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title">
              {" "}
              היסטורית הזמנה מספר: {order?.orderNumber}{" "}
            </div>
          </div>

          <div className="modal-body">
            {OrderHistoryList(order?.historyRecords, statuses, getFormatedDate)}
          </div>

          <div className="modal-footer">
            <ButtonRectangle
              extraClass="pagin-btn"
              onClick={() => setViewHistory(false)}
            >
              סגור
            </ButtonRectangle>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHistoryModal
