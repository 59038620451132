import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Spinner } from "../../../common/Spinner"
import ValidationMessage from "../../../common/ValidationMessage"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../common/NotificationModal"
import {
  cleanUserForm,
  createUser,
  editUserForm,
  clearRequestData,
} from "../../../../actions"
import { Button } from "../../../common/Button"
import "./style.scss"

const NewUser_TitleText = "יצירת משתמש"
const NewUser_FName_InputLabelText = "שם פרטי "
const NewUser_LName_InputLabelText = "שם משפחה"
const NewUser_mobilePhone_InputLabelText = "טלפון"
const NewUser_CreateSuccessed_Text = "משתמש נוצר בהצלחה"
const NewUser_CreateFailed_Text = "כישלון ביצירת משתמש"
const NewUser_IsExistError_Text = "משתמש כבר קיים. אנא צור קשר עם שרות לקוחות"

const NewUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    user,
    isLoading,
    isUserCreatedSuccess,
    isUserCreatedFail,
    userData,
    isUserExist,
  } = useSelector((state) => state.users)

  const [state, setState] = useState({
    showConfirmDeletingUser: false,
    isCreateUserButtonDisabled: false,
    firstName: "",
    lastName: "",
    mobilePhone: "",
    selectedAmshatRole: "AmshatDriver",
    validField: {
      lastName: {
        type: "text",
        isRequired: true,
        isChecked: false,
        isValid: false,
      },
      firstName: {
        type: "text",
        isRequired: false,
        isChecked: false,
        isValid: false,
      },
      mobilePhone: {
        type: "mobile",
        isRequired: false,
        isChecked: false,
        isValid: false,
      },
    },
  })

  useEffect(() => {
    return () => {
      setState({
        ...state,
        isCreateUserButtonDisabled: false,
        lastName: "",
        firstName: "",
        mobilePhone: "",
        selectedAmshatRole: "AmshatDriver",
      })
      dispatch(cleanUserForm())
    }
  }, [dispatch])

  const handleFieldChange = (inputName, inputValue) => {
    const updatedUser = { ...user, [inputName]: inputValue }
    dispatch(editUserForm(updatedUser))
    runValidation(inputName, inputValue)
  }

  const onSubmitButtonClick = () => {
    const { lastName, firstName, mobilePhone } = user
    const { orderTypeGroup } = userData

    const newUser = {
      lastName,
      firstName,
      mobilePhone,
      stationId: "1CD96853-68C6-4C14-8F99-B43C208A7319",
      role: orderTypeGroup === "6" ? state.selectedAmshatRole : "",
    }
    if (!isValidSupplier(newUser)) return

    setState({ ...state, isCreateUserButtonDisabled: true })
    dispatch(createUser(newUser))
  }

  const isValidSupplier = (newUser) => {
    const fieldsList = ["lastName", "firstName", "mobilePhone"]
    runValidationList(fieldsList, newUser)

    const { validField } = state
    return fieldsList.every((fieldName) => validField[fieldName].isValid)
  }

  const runValidationList = (fieldsList, supplier) => {
    fieldsList.forEach((fieldName) =>
      runValidation(fieldName, supplier[fieldName])
    )
  }

  const runValidation = (inputName, inputValue) => {
    let { validField } = state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true
    field.isValid = isValidField(inputValue, field.type, field.isRequired)

    validField[inputName] = field
    setState({ ...state, validField })
  }

  const onFieldFocus = (inputName) => {
    let { validField } = state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    setState({ ...state, validField })
  }

  const closeForm = () => {
    dispatch(clearRequestData())
    navigate(-1)
  }

  const handleAmshatRoleChange = (event) => {
    setState({ ...state, selectedAmshatRole: event.target?.name })
  }

  const { lastName, firstName, mobilePhone } = user
  const fieldTypeList = FIELD_TYPE_ENUM
  const { validField } = state

  if (isLoading) {
    return (
      <div className="new-user spinner-wrapper">
        <Spinner zoom={2} />
      </div>
    )
  }

  return (
    <>
      <div className="ss-container new-user">
        <h1>{NewUser_TitleText}</h1>
        {userData?.orderTypeGroup === "6" && (
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="AmshatDriver"
                checked={state.selectedAmshatRole === "AmshatDriver"}
                onChange={handleAmshatRoleChange}
              />
              Amshat Driver
            </label>
            <label>
              <input
                type="checkbox"
                name="AmshatTester"
                checked={state.selectedAmshatRole === "AmshatTester"}
                onChange={handleAmshatRoleChange}
              />
              Amshat Tester
            </label>
          </div>
        )}

        <div className="section-info-row">
          <label htmlFor="firstName">{NewUser_FName_InputLabelText}</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => handleFieldChange("firstName", e.target.value)}
            onFocus={() => onFieldFocus("firstName")}
            onBlur={() => runValidation("firstName", firstName)}
          />
          <ValidationMessage
            isShow={validField.firstName.isChecked}
            isShowOk={false}
            isShowError={!validField.firstName.isValid}
            className="field-valid"
            errorMessage={
              fieldTypeList[validField.firstName.type].errMessage +
              " " +
              fieldTypeList["required"].errMessage
            }
          />
        </div>

        <div className="section-info-row">
          <label htmlFor="lastName">{NewUser_LName_InputLabelText}</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => handleFieldChange("lastName", e.target.value)}
            onFocus={() => onFieldFocus("lastName")}
            onBlur={() => runValidation("lastName", lastName)}
          />
          <ValidationMessage
            isShow={validField.lastName.isChecked}
            isShowOk={false}
            isShowError={!validField.lastName.isValid}
            className="field-valid"
            errorMessage={
              fieldTypeList[validField.lastName.type].errMessage +
              " " +
              fieldTypeList["required"].errMessage
            }
          />
        </div>

        <div className="section-info-row">
          <label htmlFor="mobilePhone">
            {NewUser_mobilePhone_InputLabelText}
          </label>
          <input
            type="text"
            id="mobilePhone"
            value={mobilePhone}
            onChange={(e) => handleFieldChange("mobilePhone", e.target.value)}
            onFocus={() => onFieldFocus("mobilePhone")}
            onBlur={() => runValidation("mobilePhone", mobilePhone)}
          />
          <ValidationMessage
            isShow={validField.mobilePhone.isChecked}
            isShowOk={false}
            isShowError={!validField.mobilePhone.isValid}
            className="field-valid"
            errorMessage={
              fieldTypeList[validField.mobilePhone.type].errMessage +
              " " +
              fieldTypeList["required"].errMessage
            }
          />
        </div>

        <div className="btn-holder">
          <Button type="submit" onClick={onSubmitButtonClick}>
            צור משתמש
          </Button>
        </div>
      </div>

      <NotificationModal
        show={isUserCreatedSuccess}
        title={NewUser_TitleText}
        text={NewUser_CreateSuccessed_Text}
        onOkClick={closeForm}
      />
      <NotificationModal
        show={isUserCreatedFail}
        title={NewUser_TitleText}
        text={
          isUserExist ? NewUser_IsExistError_Text : NewUser_CreateFailed_Text
        }
        onOkClick={closeForm}
      />
    </>
  )
}

export default NewUser
