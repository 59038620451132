import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import MenuItem from "./MenuItem"
import OpenMenuButton from "./OpenMenuButton"
import "./style.scss"

const Menu = ({ text, isAuthenticated, role, orderTypeGroup }) => {
  const [isActive, setIsActive] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("menu")
      let targetElement = event.target

      do {
        if (targetElement === menu) {
          return
        }
        targetElement = targetElement.parentNode
      } while (targetElement)

      setIsActive(false)
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const isShowMenu = () => {
    return !(
      location.pathname === "/" || location.pathname === "/StationSelectScreen"
    )
  }

  if (!isShowMenu()) return null

  return (
    <div className="menu-wrapper" id="menu">
      <OpenMenuButton
        isActive={isActive}
        onClickMe={() => setIsActive(!isActive)}
      />
      <div className="menu-items-wrapper">
        {/* {role !== "Exelot" && (
          <MenuItem
            show={isActive}
            text="חיפוש הזמנות"
            onClick={() => {
              navigate("/OrdersListScreen")
              setIsActive(false)
            }}
          />
        )} */}

        {/* {orderTypeGroup === "1" && role === "Exelot" && (
          <MenuItem
            show={isActive}
            text="הזמנות"
            onClick={() => {
              navigate("/OrdersSearchScreen")
              setIsActive(false)
            }}
          />
        )} */}

        <MenuItem
          show={isActive}
          text="הזמנות"
          onClick={() => {
            navigate("/OrdersSearchScreen")
            setIsActive(false)
          }}
        />

        <MenuItem
          show={isActive}
          text="יצירת הזמנה"
          onClick={() => {
            navigate("/CreateNewOrderScreen")
            setIsActive(false)
          }}
        />
        <MenuItem
          show={isActive}
          text="סטטוס עמדות"
          onClick={() => {
            navigate("/NotAliveStationsScreen")
            setIsActive(false)
          }}
        />

        {role === "Decathlon" || role === "Manager" ? (
          <MenuItem
            show={isActive}
            text="עדכון הודעה"
            onClick={() => {
              navigate("/UpdateMessageScreen")
              setIsActive(false)
            }}
          />
        ) : null}

        {role !== "OneProject" && (
          <MenuItem
            show={isActive}
            text="עמדות"
            onClick={() => {
              navigate("/StationsScreen")
              setIsActive(false)
            }}
          />
        )}

        <MenuItem
          show={isActive}
          text="משתמשים"
          onClick={() => {
            navigate("/UsersListScreen")
            setIsActive(false)
          }}
        />
        <MenuItem
          show={isActive}
          text="SMS שנכשלו"
          onClick={() => {
            navigate("/FailedSMSScreen")
            setIsActive(false)
          }}
        />
        {role !== "Decathlon" && role !== "TAU" ? (
          <MenuItem
            show={isActive}
            text="דוח חיובי חבילות"
            onClick={() => {
              navigate("/PackageDebitReport")
              setIsActive(false)
            }}
          />
        ) : null}
        {role === "OneProject" ? (
          <MenuItem
            show={isActive}
            text="סריקה"
            onClick={() => {
              navigate("/ScanOrderScreen")
              setIsActive(false)
            }}
          />
        ) : null}
        <MenuItem
          show={isActive}
          text="התנתק"
          onClick={() => {
            navigate("/")
            setIsActive(false)
          }}
        />
      </div>
    </div>
  )
}

export default Menu
