import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import StationsFilterer from "../../../filters/StationsFilterer"
import StationsList from "./StationsList"
import { Pagination } from "../../common/Pagination/"
import {
  fetchStationsByRole,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
  onGetStations,
  fetchOrderTypes,
} from "../../../actions"
import "./style.scss"

const StationsScreen = ({
  authenticated,
  stations,
  isLoading,
  stationsFilteredListArr,
  pageNum,
  fetchStationsByRole,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
  fetchOrderTypes,
}) => {
  const [pagedList, setPagedList] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
    } else {
      fetchStationsByRole()
      showStationLoading()
      fetchOrderTypes()
    }

    return () => {
      clearLocalSearchStations()
    }
  }, [
    authenticated,
    fetchStationsByRole,
    showStationLoading,
    fetchOrderTypes,
    clearLocalSearchStations,
    navigate,
  ])

  const resetCurrentPage = () => {
    localSearchStationsSetPage(1)
    setPagedList([])
  }

  const onItemStatusBtnClick = (item) => {
    selectStation(item)
    navigate("/StationStatusScreen")
  }

  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Stations")
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    })
    saveAs(blob, `${fileName}.xlsx`)
  }

  const getColumnsForExportLite = (stations) => {
    return stations.map((station) => ({
      stationNumber: station.stationNumber,
      branchName: station.branchName,
      lockersNumber: station.lockersNumber,
      emptyLockersNum: station.emptyLockersNum,
    }))
  }

  const getColumnsForExportFull = (stations) => {
    return stations.map((station) => ({
      isAlive: station.isAlive,
      stationNumber: station.stationNumber,
      branchName: station.branchName,
      branchAddress: station.branchAddress,
      lockersNumber: station.lockersNumber,
      emptyDeliveryLockersNum: station.emptyDeliveryLockersNum,
      brokenLockersNum: station.brokenLockersNum,
      branchDisplayAddress: station.branchDisplayAddress,
      availabilityHours: station.availabilityHours,
      cityName: station.cityName,
    }))
  }

  const createFileName = () => {
    const date = new Date().toLocaleDateString()
    const time = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    })
    return `station_${date}_${time}`
  }

  return (
    <div className="stations-page-wrapper">
      <h1>רשימת עמדות</h1>
      <div className="stations-header">
        <StationsFilterer
          key={stations}
          resetCurrentPage={resetCurrentPage}
          stations={stations}
        />
        <button
          onClick={() =>
            exportToExcel(getColumnsForExportLite(stations), createFileName())
          }
          className="stations-export"
        >
          ייצוא לאקסל חלקי
        </button>
        <div className="export">
          <button
            onClick={() =>
              exportToExcel(getColumnsForExportFull(stations), createFileName())
            }
            className="stations-export"
          >
            ייצוא לאקסל הכל
          </button>
        </div>
      </div>
      <div key={pagedList.length}>
        <StationsList
          items={pagedList}
          isLoading={isLoading}
          onItemStatusBtnClick={onItemStatusBtnClick}
        />
        <Pagination
          fullList={stationsFilteredListArr}
          totalPerPage={50}
          onSubListReady={(pagedList, pageNum) => {
            localSearchStationsSetPage(pageNum)
            setPagedList(pagedList)
          }}
          pageNum={pageNum}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { stations, stationsLocalSearch, isLoading } = state.station
  const { authenticated } = state.users
  const { stationsFilteredListArr, page } = stationsLocalSearch || {}

  return {
    isLoading,
    stationsFilteredListArr,
    stations,
    pageNum: page || 1,
    authenticated,
  }
}

export default connect(mapStateToProps, {
  fetchStationsByRole,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
  onGetStations,
  fetchOrderTypes,
})(StationsScreen)
