import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearLockersStatuses } from "../../../actions"
import LockerCells from "../../common/LockerCells"
import { ButtonRectangle } from "../../common/ButtonRectangle"
import "./style.scss"

const StationStatusScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { authenticated, selectedStationId } = useSelector((state) => ({
    authenticated: state.users.authenticated,
    selectedStationId: state.station.selectedStationId,
  }))

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
    }

    return () => {
      dispatch(clearLockersStatuses())
    }
  }, [authenticated, navigate, dispatch])

  return (
    <div className="station-status-wrapper">
      <div className="btn-back-wrapper">
        <ButtonRectangle extraClass="btn-back" onClick={() => navigate(-1)}>
          חזור
        </ButtonRectangle>
      </div>

      <LockerCells stationId={selectedStationId} />
    </div>
  )
}

export default StationStatusScreen
