import React, { Component, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../common/Button"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import NotificationModal from "../NotificationModal"
import { clearOrderStatusUpdate } from "../../../actions"
import "./style.scss"

const OrderDelayModal = ({ isOpen, isClose, submitDate, inLockerDate }) => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const { orderStatusUpdateSuccess, orderStatusUpdateFail } = useSelector(
    (state) => state.orders
  )
  const handleDateChange = (date) => {
    const currentDate = new Date()
    if (date >= currentDate) {
      setSelectedDate(date)
    } else {
      setShowWarning(true)
    }
  }

  useEffect(() => {
    if (inLockerDate && inLockerDate !== "0001-01-01T00:00:00") {
      setSelectedDate(new Date(inLockerDate))
    }
  }, [inLockerDate])

  const validateDate = (date) => {
    if (date == null || date == "0001-01-01T00:00:00") {
      setShowWarning(true)
    } else {
      submitDate(selectedDate)
    }
  }

  const closeModal = () => {
    dispatch(clearOrderStatusUpdate())
    isClose()
  }

  if (!isOpen) {
    return null
  }
  return (
    <div className="orderDelayModal">
      <div className="modal-content">
        <h2>בחר תאריך להארכת זמן חבילה בלוקר</h2>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          isClearable={false}
          placeholderText="בחר תאריך"
        />

        <div className="btn-holder">
          <Button onClick={(e) => validateDate(selectedDate)}>אישור</Button>
          <Button onClick={(e) => isClose()}>סגור</Button>
        </div>
      </div>
      <NotificationModal
        type={"warning"}
        title={"תאריך לא תקין"}
        show={showWarning}
        text={"אנא בחר תאריך עתידי."}
        onOkClick={() => setShowWarning(false)}
      />
      <NotificationModal
        type={"success"}
        title={"הארכת זמן חבילה בלוקר"}
        show={orderStatusUpdateSuccess}
        text={" ההזמנה עודכנה בהצלחה"}
        onOkClick={() => closeModal()}
      />
      <NotificationModal
        type={"error"}
        title={"הארכת זמן חבילה בלוקר"}
        show={orderStatusUpdateFail}
        text={" ההזמנה לא עודכנה"}
        onOkClick={() => closeModal()}
      />
    </div>
  )
}

export default OrderDelayModal
