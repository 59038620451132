import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  fetchStationsByRole,
  showStationLoading,
  setDecathlonSelectedStation,
} from "../../../actions"
import "./style.scss"

const StationSelectScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { authenticated, stationsFilteredListArr, selectedDecathlonStation } =
    useSelector(({ station, users }) => {
      const { stationsLocalSearch, selectedDecathlonStation } = station
      const { stationsFilteredListArr } = stationsLocalSearch
      const { authenticated } = users
      return {
        authenticated,
        stationsFilteredListArr,
        selectedDecathlonStation,
      }
    })

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }
    dispatch(fetchStationsByRole())
    dispatch(showStationLoading())
  }, [authenticated, dispatch, navigate])

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
    }
  }, [authenticated, navigate])

  const onSelectStation = (stationNumber) => {
    dispatch(setDecathlonSelectedStation(stationNumber))
    navigate("/OrdersSearchScreen")
  }

  return (
    <div className="decathlon-station-select">
      {stationsFilteredListArr.map((station) => (
        <div
          key={station.stationNumber}
          className="station"
          onClick={() => onSelectStation(station.stationNumber)}
        >
          {station.branchName}
        </div>
      ))}
    </div>
  )
}

export default StationSelectScreen
