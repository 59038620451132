import React, { useState, useMemo } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table"
import { Button as ButtonReact, Input } from "reactstrap"
import { Button } from "../Button"
import * as XLSX from "xlsx/xlsx.mjs"
import "./style.scss"

const TableContainer = ({ columns, data, showTablePagination }) => {
  const [sorting, setSorting] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")
  const [pageSize, setPageSize] = useState(50)
  const [columnFilters, setColumnFilters] = useState([])

  const enhancedColumns = useMemo(() => {
    return columns.map((column) => {
      if (
        ["packageNumber", "mobilePhone", "firstName", "lastName"].includes(
          column.id
        )
      ) {
        return {
          ...column,
          header: ({ column: columnProps }) => (
            <div>
              <div
                {...{
                  onClick: (e) => {
                    if (e.target !== e.currentTarget) return
                    columnProps.getToggleSortingHandler()(e)
                  },
                }}
              >
                {column.header()}
              </div>

              <Input
                type="text"
                value={columnProps.getFilterValue() || ""}
                onChange={(e) => {
                  columnProps.setFilterValue(e.target.value || undefined)
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ),
        }
      }

      if (column.id === "orderStatus") {
        return {
          ...column,
          header: ({ column: columnProps }) => (
            <div>
              <div
                {...{
                  onClick: (e) => {
                    if (e.target !== e.currentTarget) return
                    columnProps.getToggleSortingHandler()(e)
                  },
                }}
              >
                {column.header()}
              </div>
              <Input
                type="select"
                value={columnProps.getFilterValue() || ""}
                onChange={(e) => {
                  columnProps.setFilterValue(e.target.value || undefined)
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <option value="">All Statuses</option>
                {Array.from(new Set(data.map((row) => row.orderStatus))).map(
                  (status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  )
                )}
              </Input>
            </div>
          ),
        }
      }

      return column
    })
  }, [columns, data])

  const table = useReactTable({
    data,
    columns: enhancedColumns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: { pagination: { pageSize } },
  })

  const exportToExcel = () => {
    const exportData = table.getRowModel().rows.map((row) => {
      const rowData = {}
      row.getAllCells().forEach((cell) => {
        rowData[cell.column.columnDef.header] = cell.getValue()
      })
      return rowData
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(exportData)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data")
    XLSX.writeFile(workbook, "Export.xlsx")
  }

  return (
    <div className="TableContainer">
      <div className="list-counter">
        <span className="counter">{`נמצאו ${
          table.getFilteredRowModel().rows.length
        } רשומות`}</span>
      </div>
      <div className="table-container">
        {showTablePagination && (
          <div className="pagination">
            <ButtonReact
              color="primary"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </ButtonReact>
            <ButtonReact
              color="primary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </ButtonReact>
            <span>
              דף{" "}
              <strong>
                {table.getState().pagination.pageIndex + 1} מתוך{" "}
                {table.getPageCount()}
              </strong>
            </span>
            <ButtonReact
              color="primary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </ButtonReact>
            <ButtonReact
              color="primary"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </ButtonReact>
            <Input
              type="select"
              value={pageSize}
              onChange={(e) => {
                const size = Number(e.target.value)
                setPageSize(size)
                table.setPageSize(size)
              }}
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  הצג {size}
                </option>
              ))}
            </Input>
            <Button className="export-btn" onClick={exportToExcel}>
              ייצוא לאקסל
            </Button>
          </div>
        )}
        <div className="scrolled-wrapper">
          <table className="table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getIsSorted() === "asc"
                            ? " 🔼"
                            : header.column.getIsSorted() === "desc"
                            ? " 🔽"
                            : ""}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TableContainer
