import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Routes, Route } from "react-router"
import Store from "./store"

import AppLayout from "./components/AppLayout"
import LoginScreen from "./components/screens/LoginScreen"
import OrdersListScreen from "./components/screens/OrdersListScreen"
import OrdersSearchScreen from "./components/screens/OrdersSearchScreen"
import CreateNewOrderScreen from "./components/screens/CreateNewOrderScreen"
import UpdateOrderScreen from "./components/screens/UpdateOrderScreen"
import UpdateMessageScreen from "./components/screens/UpdateMessageScreen"
import StationStatusScreen from "./components/screens/StationStatusScreen"
import StationsScreen from "./components/screens/StationsScreen"
import ChangePasswordScreen from "./components/screens/ChangePasswordScreen"
import UsersListScreen from "./components/screens/UsersListScreen"
import NewUser from "./components/screens/UsersListScreen/NewUser"
import PackageDebitReport from "./components/screens/PackageDebitReport"
import StationSelectScreen from "./components/screens/StationSelectScreen"
import ScanOrderScreen from "./components/screens/ScanOrderScreen"
import FailedSMSScreen from "./components/screens/FailedSMSScreen"
import NotAliveStationsScreen from "./components/screens/NotAliveStationsScreen"
import RelocateOrderScreen from "./components/screens/RelocateOrderScreen"

import "./App.scss"

const App = () => {
  return (
    <Provider store={Store}>
      <Router>
        <AppLayout>
          <Routes>
            <Route path="/OrdersListScreen" element={<OrdersListScreen />} />
            <Route
              path="/OrdersSearchScreen"
              element={<OrdersSearchScreen />}
            />
            <Route
              path="/CreateNewOrderScreen"
              element={<CreateNewOrderScreen />}
            />
            <Route path="/UpdateOrderScreen" element={<UpdateOrderScreen />} />
            <Route
              path="/UpdateMessageScreen"
              element={<UpdateMessageScreen />}
            />
            <Route
              path="/StationStatusScreen"
              element={<StationStatusScreen />}
            />
            <Route
              path="/ChangePasswordScreen"
              element={<ChangePasswordScreen />}
            />
            <Route path="/UsersListScreen" element={<UsersListScreen />} />
            <Route path="/FailedSMSScreen" element={<FailedSMSScreen />} />
            <Route
              path="/PackageDebitReport"
              element={<PackageDebitReport />}
            />
            <Route
              path="/StationSelectScreen"
              element={<StationSelectScreen />}
            />
            <Route path="/ScanOrderScreen" element={<ScanOrderScreen />} />
            <Route
              path="/NotAliveStationsScreen"
              element={<NotAliveStationsScreen />}
            />
            <Route
              path="/RelocateOrderScreen"
              element={<RelocateOrderScreen />}
            />
            <Route path="/NewUser" element={<NewUser />} />
            <Route path="/StationsScreen" element={<StationsScreen />} />
            <Route path="/" element={<LoginScreen />} />
          </Routes>
        </AppLayout>
      </Router>
    </Provider>
  )
}

export default App
