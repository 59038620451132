import React from "react"
import Modal from "../Modal"
import { ButtonRectangle } from "../ButtonRectangle"
import "./style.scss"

const YesNoModal = ({ show, title, text, onYesClick, onNoClick }) => {
  if (!show) return null

  return (
    <div className="yesno-modal-overlay">
      <div className="yesno-modal-container">
        {title && <h2 className="yesno-modal-title">{title}</h2>}
        <p className="yesno-modal-text">{text}</p>
        <div className="yesno-modal-btns-wrapper">
          <ButtonRectangle
            className="yesno-modal-button yes"
            onClick={onYesClick}
          >
            אישור
          </ButtonRectangle>
          <ButtonRectangle
            className="yesno-modal-button no"
            onClick={onNoClick}
          >
            ביטול
          </ButtonRectangle>
        </div>
      </div>
    </div>
  )
}

export default YesNoModal
