import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import edit_blue from "../../../../assets/svg/edit_blue.svg"
import "./style.scss"
import { createColumnHelper, getFilteredRowModel } from "@tanstack/react-table"

const OrdersList = (props) => {
  const [filterValue, setFilterValue] = useState("")
  const {
    orderList,
    onClick,
    onRelocateClick,
    ordersStatusesArr,
    userData,
    refreshTable,
    isLoadingSearch,
    selectedDecathlonStation,
  } = props

  const formateOrder = (order) => {
    const formatted = {
      id: order.id,
      branchDisplayName: order.branchDisplayName || "",
      orderNumber: order.orderNumber || "",
      packageNumber: order.packageNumber || "",
      externalOrderNumber: setexternalOrderNumber(
        order.externalOrderNumber,
        order.externalOrderNumber2
      ),
      mobilePhone: setEmtyIfNull(order.mobilePhone),
      firstName: setEmtyIfNull(order.firstName),
      lastName: setEmtyIfNull(order.lastName),
      createdByName: order.createdByName || "",
      orderStatus: ordersStatusesArr[order.orderStatus] || "",
      orderDate: getFormatedDate(order.orderDate) || "",
      inLockerExtentionDate: getFormatedDate(order.inLockerExtentionDate) || "",
    }
    return formatted
  }

  const setexternalOrderNumber = (
    externalOrderNumber,
    externalOrderNumber2
  ) => {
    if (externalOrderNumber != null) {
      return externalOrderNumber
    } else if (externalOrderNumber2 != null) {
      return externalOrderNumber2
    }
    return ""
  }

  const ordersByStation = useMemo(() => {
    if (orderList) {
      if (selectedDecathlonStation) {
        return orderList
          .filter((order) => order.stationNumber === selectedDecathlonStation)
          .map(formateOrder)
      }
      return orderList.map(formateOrder)
    }
    return []
  }, [orderList, selectedDecathlonStation])

  const data = useMemo(() => ordersByStation, [ordersByStation])

  const columnHelper = createColumnHelper() // Create column helper

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor("orderDate", {
        id: "orderDate",
        header: "תאריך",
      }),
      columnHelper.accessor("packageNumber", {
        id: "packageNumber",
        header: () => "מספר חבילה",
      }),
      columnHelper.accessor("mobilePhone", {
        id: "mobilePhone",
        header: () => "מספר טלפון",
        cell: (info) => info.getValue() || "N/A",
      }),
      columnHelper.accessor("firstName", {
        id: "firstName",
        header: () => "שם פרטי",
      }),
      columnHelper.accessor("lastName", {
        id: "lastName",
        header: () => "שם משפחה",
      }),
      columnHelper.accessor("orderStatus", {
        id: "orderStatus",
        header: () => "סטטוס",
        filterFn: "includesString",
      }),
      columnHelper.accessor("createdByName", {
        id: "createdByName",
        header: "נוצר על ידי",
      }),
      columnHelper.accessor("branchDisplayName", {
        id: "branchDisplayName",
        header: "שם העמדה",
      }),
      columnHelper.accessor("inLockerExtentionDate", {
        id: "inLockerExtentionDate",
        header: "תאריך הארכה",
      }),
    ]

    if (userData?.orderTypeGroup !== "5") {
      result.push(
        columnHelper.accessor("orderNumber", {
          id: "orderNumber",
          header: "מספר הזמנה",
        })
      )
    }

    if (userData?.orderTypeGroup !== "5" && userData?.role !== "TAU") {
      result.push(
        columnHelper.accessor("externalOrderNumber", {
          id: "externalOrderNumber",
          header: "שטר מטען",
        })
      )
    }

    if (userData) {
      result.unshift(
        columnHelper.accessor("edit", {
          id: "edit",
          header: () => null,
          width: 26,
          cell: (props) => (
            <div className="order-edit">
              <img
                alt="edit_button"
                onClick={() => onClick(props.cell.row.original.orderNumber)}
                src={edit_blue}
              />
            </div>
          ),
        })
      )

      result.unshift(
        columnHelper.accessor("relocate", {
          id: "relocate",
          header: () => null,
          width: 26,
          cell: ({ row }) => {
            const { orderStatus: status } = row.original
            const typeGroup = userData?.orderTypeGroup
            return typeGroup !== 1 &&
              (status === "מוכן בלוקר" || status === "סומן לחזרה לשולח") ? (
              <div
                className="fa fa-truck"
                onClick={() => onRelocateClick(row.original.orderNumber)}
              />
            ) : null
          },
        })
      )
    }

    return result
  }, [userData, filterValue])

  const clearFilterAndRefreshTable = () => {
    setFilterValue("")
    refreshTable()
  }

  return (
    <div className="order-list-wrapper">
      <Container className="order-list">
        <TableContainer
          columns={columns}
          data={data}
          refreshTable={clearFilterAndRefreshTable}
          filterValue={filterValue}
          resetFilterValue={() => setFilterValue("")}
          isLoadingSearch={isLoadingSearch}
          showTablePagination={true}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({ orders, orderStatuses, users, station }) => {
  const { orderList } = orders
  const { userData } = users
  const { selectedDecathlonStation } = station
  const { ordersStatusesArr } = orderStatuses
  return { orderList, ordersStatusesArr, userData, selectedDecathlonStation }
}

export default connect(mapStateToProps)(OrdersList)
