import React from "react"
import { Button } from "../../common/Button"
import "./style.scss"

const NotificationModal = ({ show, title, text, onOkClick, extraClass }) => {
  if (!show) {
    return null
  }

  return (
    <div className="noti-modal-wrap">
      <div className="noti-modal-backdrop"></div>
      <div className={`noti-modal-container ${extraClass}`}>
        {title && <div className="noti-modal-header">{title}</div>}
        <div className="noti-modal-content-wrapper">{text}</div>
        <div className="btn-holder">
          <Button extraClass="noti-modal-button" onClick={onOkClick}>
            אישור
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal
